/* You can add global styles to this file, and also import other style files */
/*================================================
Default CSS
=================================================*/
body {
  font-size: 16px;
  line-height: 1.8;
  font-family: "Poppins", sans-serif;
  color: #301d44;
  font-weight: 400;
}

p {
  color: #301d44;
}

a {
  display: inline-block;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
}

button {
  margin: 0;
  padding: 0;
  outline: 0;
}
button:focus {
  outline: 0;
  border: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  line-height: 1.4;
  color: #301d44;
}

h3 {
  font-size: 24px;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  display: table-cell;
  vertical-align: middle;
}

img {
  max-width: 100%;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-80 {
  padding-top: 80px;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pt-45 {
  padding-top: 45px;
}

.pb-20 {
  padding-bottom: 20px;
}

/*================================
Default btn Style
===================================*/
.default-btn {
  padding: 14px 27px;
  background-color: #fba311;
  color: #ffffff;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}
.default-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
  background-color: #5a3ac7;
  opacity: 0;
  transition: 0.7s;
  border-radius: 5px;
}
.default-btn:hover {
  background-color: #5a3ac7;
  color: #ffffff;
}
.default-btn:hover::before {
  height: 100%;
  opacity: 1;
  border-radius: 5px;
}

/*================================
Section Title Style
===================================*/
.section-title span {
  line-height: 0;
  text-transform: capitalize;
  padding-bottom: 5px;
  font-weight: 400;
  color: #fba311;
}
.section-title h2 {
  font-size: 35px;
  font-weight: 600;
  margin-top: 5px;
  line-height: 1.4;
  color: #301d44;
}
.section-title p {
  padding-top: 10px;
  margin-bottom: 0;
}

/*================================
Section Title Style End
===================================*/
.top-header {
  background-color: #301d44;
}

.header-left {
  text-align: left;
  margin-top: 5px;
}
.header-left .header-left-card ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.header-left .header-left-card ul li {
  display: inline-block;
  text-align: left;
  position: relative;
  padding-left: 7px;
  color: #ffffff;
  margin-right: 35px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.header-left .header-left-card ul li::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 24px;
  background-color: #645673;
  left: -20px;
  top: 17px;
}
.header-left .header-left-card ul li:first-child::before {
  display: none;
}
.header-left .header-left-card ul li:last-child {
  margin-right: 0;
}
.header-left .header-left-card ul li .head-icon {
  color: #fba311;
  font-size: 24px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 50%;
  transition: 0.5s;
  transform: translateY(-50%);
}
.header-left .header-left-card ul li a {
  display: inline-block;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  margin-left: 30px;
}

.header-right {
  float: right;
}

.top-social-link {
  padding-top: 15px;
  padding-bottom: 10px;
  display: inline-block;
  margin-right: 30px;
  position: relative;
}
.top-social-link::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 24px;
  background-color: #645673;
  right: -22px;
  top: 20px;
}
.top-social-link ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.top-social-link ul li {
  display: inline-block;
  margin-right: 5px;
}
.top-social-link ul li a {
  width: 30px;
  height: 30px;
  line-height: 32px;
  text-align: center;
  background-color: #fba311;
  color: #ffffff;
}
.top-social-link ul li a:hover {
  background-color: #ffffff;
  color: #fba311;
}

.language {
  margin-top: 5px;
  float: right;
  z-index: 55;
  position: relative;
}
.language ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.language ul li {
  display: inline-block;
}
.language ul li:hover {
  color: #fba311;
}
.language ul li:hover ul {
  display: block;
}
.language ul li a {
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 400;
  padding: 15px;
  display: block;
}
.language ul li a .language-icon {
  color: #fba311 !important;
  margin-right: 5px;
}
.language ul li a:hover {
  color: #fba311;
}
.language ul ul {
  position: absolute;
  background: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 1;
  border-radius: 3px;
}
.language ul ul li {
  display: block;
  background: #fff;
  padding: 3px 15px;
  border-radius: 3px;
}
.language ul ul li:hover {
  color: #301d44;
}
.language ul ul li a {
  color: #301d44;
  padding: 0 25px;
  font-size: 14px;
}
.language ul ul li a:hover {
  color: #fba311;
}
.language ul ul li i {
  color: #292929;
  float: right;
  padding-left: 20px;
}

/*=================================
Navbar Area
====================================*/
.navbar-area {
  background-color: transparent;
  transition: 0.3s;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
}

.navbar-light {
  padding: 0;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
}
.navbar-light .navbar-brand img {
  float: left;
  padding: 10px 0;
}
.navbar-light .navbar-brand-sticky {
  display: none;
}

/* Main nav */
.navbar-area {
  top: 0;
  left: 0;
  padding: 0;
  width: 100%;
  height: auto;
  z-index: inherit;
  position: inherit;
  background-color: #ffffff;
}
.navbar-area .navbar-light {
  background-color: transparent !important;
}
.navbar-area nav .navbar-nav .nav-item {
  position: relative;
  margin-left: 12px;
  margin-right: 12px;
}
.navbar-area nav .navbar-nav .nav-item a {
  position: relative;
  color: #301d44;
  transition: 0.5s;
  font-weight: 600;
  font-size: 15.2px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 25px;
  padding-bottom: 25px;
}
.navbar-area nav .navbar-nav .nav-item a:hover, .navbar-area nav .navbar-nav .nav-item a.active {
  color: #fba311;
}
.navbar-area nav .navbar-nav .nav-item a::after {
  display: none;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-toggle {
  padding-right: 15px;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-toggle i {
  position: absolute;
  font-size: 18px;
  right: -3px;
  top: 30px;
}
.navbar-area nav .navbar-nav .nav-item:last-child {
  margin-right: 0;
}
.navbar-area nav .navbar-nav .nav-item:first-child {
  margin-left: 0;
}
.navbar-area nav .navbar-nav .nav-item:hover a, .navbar-area nav .navbar-nav .nav-item.active a {
  color: #fba311;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu {
  left: 0;
  top: 77px;
  opacity: 0;
  z-index: 99;
  border: none;
  width: 250px;
  margin-top: 0;
  display: block;
  padding: 10px 0;
  border-radius: 0;
  position: absolute;
  visibility: hidden;
  background: #ffffff;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li {
  margin: 0;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li a {
  display: block;
  padding: 8px 20px;
  position: relative;
  color: #301d44;
  font-size: 14px;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li a:hover {
  color: #fba311;
  padding-left: 25px;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #fba311;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li a.dropdown-toggle i {
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  left: 250px;
  margin-top: 0;
  visibility: hidden;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #301d44;
  padding-left: 20px;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover {
  color: #fba311;
  padding-left: 25px;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #fba311;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  right: 250px;
  visibility: hidden;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #301d44;
  padding-left: 20px;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover {
  padding-left: 25px;
  color: #fba311;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #fba311;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  top: 0;
  opacity: 0;
  right: 250px;
  visibility: hidden;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  padding-left: 20px;
  color: #301d44;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover {
  color: #fba311;
  padding-left: 25px;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #fba311;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #fba311;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #fba311;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #fba311;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #fba311;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  margin-top: 0;
  visibility: visible;
}
.navbar-area nav .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}
.navbar-area nav .navbar-nav.for-responsive {
  display: none;
}
.navbar-area .others-options .option-item {
  margin-right: 20px;
}
.navbar-area .others-options .option-item:last-child {
  margin-right: 0;
}
.navbar-area .others-options .option-item .search-btn {
  font-size: 30px;
  margin-top: 10px;
  color: #5a3ac7;
  transition: 0.5s;
  cursor: pointer;
}
.navbar-area .others-options .option-item .search-btn:hover {
  color: #fba311;
  transform: translateY(-5%);
}
.navbar-area .others-options .option-item .close-btn {
  font-size: 30px;
  color: #5a3ac7;
  transition: 0.5s;
  display: none;
  cursor: pointer;
}
.navbar-area .others-options .option-item .close-btn:hover {
  color: #fba311;
  transform: translateY(-5%);
}
.navbar-area .others-options .option-item .close-btn.active {
  display: block;
}
.navbar-area .others-options .option-item .menu-icon .menu-icon-two {
  font-size: 30px;
  cursor: pointer;
  color: #5a3ac7;
  cursor: pointer;
}
.navbar-area .others-options .option-item .menu-icon .menu-icon-two:hover {
  color: #fba311;
}

.search-overlay {
  display: none;
}
.search-overlay.search-popup {
  position: absolute;
  top: 100%;
  width: 300px;
  background: #ffffff;
  z-index: 2;
  right: 0;
  padding: 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  margin-top: 0;
}
.search-overlay.search-popup .search-form {
  position: relative;
}
.search-overlay.search-popup .search-form .search-input {
  display: block;
  width: 100%;
  height: 50px;
  line-height: initial;
  border: 1px solid #eeeeee;
  color: #301d44;
  outline: 0;
  transition: 0.5s;
  padding-top: 4px;
  padding-left: 10px;
}
.search-overlay.search-popup .search-form .search-input:focus {
  border-color: #fba311;
}
.search-overlay.search-popup .search-form .search-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  background: transparent;
  border: none;
  width: 50px;
  outline: 0;
  color: #301d44;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 0;
}
.search-overlay.search-popup .search-form .search-button:focus {
  color: #fba311;
}
.search-overlay.search-popup .search-form .search-button i {
  font-size: 18px;
  font-weight: bold;
}

.sticky-nav {
  top: 0;
  z-index: 999;
  position: fixed;
  transition: 0.9s;
  width: 100% !important;
  animation: 900ms ease-in-out 5s normal none 1 running fadeInDown;
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
  .navbar-area .navbar .navbar-nav {
    display: none;
    max-height: 60vh;
    flex-direction: unset;
    background-color: #f9f9f9;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 15px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-top: 18px;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 18px;
  }
  .navbar-area .navbar .navbar-nav .nav-item {
    margin-left: 0;
    margin-right: 0;
    margin-top: 18px;
    margin-bottom: 18px;
  }
  .navbar-area .navbar .navbar-nav .nav-item .nav-link {
    font-size: 13.5px;
    color: #301d44;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0 !important;
  }
  .navbar-area .navbar .navbar-nav .nav-item .nav-link.active, .navbar-area .navbar .navbar-nav .nav-item .nav-link:hover {
    color: #fba311;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-toggle::before {
    font-size: 18px;
    right: 0;
    top: 2px;
  }
  .navbar-area .navbar .navbar-nav .nav-item:first-child {
    margin-top: 0;
  }
  .navbar-area .navbar .navbar-nav .nav-item:last-child {
    margin-bottom: 0;
  }
  .navbar-area .navbar .navbar-nav .nav-item:hover a, .navbar-area .navbar .navbar-nav .nav-item.active a {
    color: #fba311;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu {
    opacity: 1;
    width: 100%;
    padding: 18px;
    top: 0 !important;
    box-shadow: unset;
    position: relative;
    visibility: visible;
    margin-top: 12px !important;
    background-color: #ffffff;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu .nav-item {
    margin-left: 0;
    margin-right: 0;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu .nav-item:first-child {
    margin-top: 0;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu .nav-item:last-child {
    margin-bottom: 0;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link {
    margin: 0;
    padding: 0;
    font-size: 13px;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link.dropdown-toggle::before {
    top: 2px;
    right: 0;
    transform: unset;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link.active, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link:hover {
    color: #fba311;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .dropdown-menu {
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
    width: auto;
    visibility: visible;
    margin: 15px 0 0 !important;
    background-color: #f9f9f9;
  }
  .navbar-area .navbar .navbar-nav.for-responsive {
    display: block;
  }
  .navbar-light .navbar-toggler {
    color: #301d44;
    font-size: inherit;
    box-shadow: unset;
    border: none;
    padding: 0;
  }
  .navbar-light .navbar-toggler .burger-menu {
    cursor: pointer;
  }
  .navbar-light .navbar-toggler .burger-menu span {
    height: 3px;
    width: 30px;
    margin: 5px 0;
    display: block;
    background: #301d44;
  }
  .navbar-light.active .navbar-toggler .burger-menu span.top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
  }
  .navbar-light.active .navbar-toggler .burger-menu span.middle-bar {
    opacity: 0;
  }
  .navbar-light.active .navbar-toggler .burger-menu span.bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
    margin-top: 5px;
  }
  .navbar-light.active .collapse:not(.show) {
    display: block;
  }
  .sidebar-modal {
    display: none;
  }
}
/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-area .navbar .navbar-nav {
    display: none;
    max-height: 60vh;
    flex-direction: unset;
    background-color: #f9f9f9;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 15px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-top: 18px;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 18px;
  }
  .navbar-area .navbar .navbar-nav .nav-item {
    margin-left: 0;
    margin-right: 0;
    margin-top: 18px;
    margin-bottom: 18px;
  }
  .navbar-area .navbar .navbar-nav .nav-item .nav-link {
    font-size: 13.5px;
    color: #301d44;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0 !important;
  }
  .navbar-area .navbar .navbar-nav .nav-item .nav-link.active, .navbar-area .navbar .navbar-nav .nav-item .nav-link:hover {
    color: #fba311;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-toggle::before {
    font-size: 18px;
    right: 0;
    top: 2px;
  }
  .navbar-area .navbar .navbar-nav .nav-item:first-child {
    margin-top: 0;
  }
  .navbar-area .navbar .navbar-nav .nav-item:last-child {
    margin-bottom: 0;
  }
  .navbar-area .navbar .navbar-nav .nav-item:hover a, .navbar-area .navbar .navbar-nav .nav-item.active a {
    color: #fba311;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu {
    opacity: 1;
    width: 100%;
    padding: 18px;
    top: 0 !important;
    box-shadow: unset;
    position: relative;
    visibility: visible;
    margin-top: 12px !important;
    background-color: #ffffff;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu .nav-item {
    margin-left: 0;
    margin-right: 0;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu .nav-item:first-child {
    margin-top: 0;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu .nav-item:last-child {
    margin-bottom: 0;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link {
    margin: 0;
    padding: 0;
    font-size: 13px;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link.dropdown-toggle::before {
    top: 2px;
    right: 0;
    transform: unset;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link.active, .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link:hover {
    color: #fba311;
  }
  .navbar-area .navbar .navbar-nav .nav-item .dropdown-menu .nav-item .dropdown-menu {
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
    width: auto;
    visibility: visible;
    margin: 15px 0 0 !important;
    background-color: #f9f9f9;
  }
  .navbar-area .navbar .navbar-nav.for-responsive {
    display: block;
  }
  .navbar-light .navbar-toggler {
    color: #301d44;
    font-size: inherit;
    box-shadow: unset;
    border: none;
    padding: 0;
  }
  .navbar-light .navbar-toggler .burger-menu {
    cursor: pointer;
  }
  .navbar-light .navbar-toggler .burger-menu span {
    height: 3px;
    width: 30px;
    margin: 5px 0;
    display: block;
    background: #301d44;
  }
  .navbar-light.active .navbar-toggler .burger-menu span.top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
  }
  .navbar-light.active .navbar-toggler .burger-menu span.middle-bar {
    opacity: 0;
  }
  .navbar-light.active .navbar-toggler .burger-menu span.bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
    margin-top: 5px;
  }
  .navbar-light.active .collapse:not(.show) {
    display: block;
  }
  .sidebar-modal {
    display: none;
  }
}
/*=================================
Sidebar Modal
====================================*/
.sidebar-modal {
  position: fixed;
  right: 0;
  top: 0;
  width: 200%;
  height: 100%;
  background-color: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  overflow: hidden;
}
.sidebar-modal .sidebar-modal-inner {
  position: absolute;
  right: -100%;
  top: 0;
  width: 400px;
  overflow-y: scroll;
  height: 100%;
  background-color: #ffffff;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
  padding: 40px;
}
.sidebar-modal .sidebar-header {
  margin-bottom: 30px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 30px;
}
.sidebar-modal .sidebar-header .sidebar-logo {
  display: inline-block;
}
.sidebar-modal .sidebar-header .close-btn {
  display: inline-block;
  font-size: 20px;
  transition: all 0.5s;
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #272b2b;
  color: #ffffff;
  border-radius: 0;
  text-align: center;
  margin-top: 15px;
  float: right;
}
.sidebar-modal .sidebar-header .close-btn:hover {
  color: #ffffff;
  background-color: #272b2b;
}
.sidebar-modal .sidebar-about {
  margin-bottom: 30px;
}
.sidebar-modal .sidebar-about .title h2 {
  margin-bottom: 0;
  font-size: 24px;
}
.sidebar-modal .sidebar-about .title p {
  margin-bottom: 0;
  font-size: 15px;
}
.sidebar-modal .contact-us {
  margin-bottom: 30px;
}
.sidebar-modal .contact-us h2 {
  font-size: 20px;
  margin-bottom: 25px;
}
.sidebar-modal .contact-us ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.sidebar-modal .contact-us ul li {
  position: relative;
  padding-left: 40px;
  margin-bottom: 20px;
  color: #301d44;
}
.sidebar-modal .contact-us ul li:last-child {
  margin-bottom: 0;
}
.sidebar-modal .contact-us ul li i {
  position: absolute;
  top: 5px;
  left: 0;
  font-size: 28px;
  color: #fba311;
}
.sidebar-modal .contact-us ul li a {
  display: block;
  color: #301d44;
}
.sidebar-modal .sidebar-instagram-feed {
  margin-bottom: 30px;
}
.sidebar-modal .sidebar-instagram-feed h2 {
  margin-bottom: 25px;
  font-size: 20px;
}
.sidebar-modal .sidebar-instagram-feed ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 0;
  margin-top: -10px;
  max-width: 275px;
}
.sidebar-modal .sidebar-instagram-feed ul li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33%;
  flex: 0 0 33%;
  max-width: 33%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}
.sidebar-modal .sidebar-instagram-feed ul li a {
  display: block;
  position: relative;
}
.sidebar-modal .sidebar-instagram-feed ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
}
.sidebar-modal .sidebar-instagram-feed ul li a:hover::before {
  opacity: 0.5;
  visibility: visible;
}
.sidebar-modal .sidebar-follow-us h2 {
  font-size: 20px;
  margin-bottom: 25px;
}
.sidebar-modal .sidebar-follow-us .social-wrap {
  line-height: 1;
}
.sidebar-modal .sidebar-follow-us .social-wrap {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sidebar-modal .sidebar-follow-us .social-wrap li {
  display: inline-block;
  padding-right: 10px;
}
.sidebar-modal .sidebar-follow-us .social-wrap li a i {
  font-size: 20px;
  color: #fba311;
}
.sidebar-modal .sidebar-follow-us .social-wrap li a:hover {
  transform: translateY(-2px);
}
.sidebar-modal.active {
  opacity: 1;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.9);
}
.sidebar-modal.active .sidebar-modal-inner {
  right: 0;
}

/*=================================
Sidebar Modal
====================================*/
/*=================================
Main Banner Area
====================================*/
.banner-area {
  background-color: #5a3ac7;
  position: relative;
  overflow: hidden;
}
.banner-area::before {
  content: "";
  position: absolute;
  overflow: hidden;
  z-index: 1;
  left: 20%;
  top: 0;
  width: 90%;
  height: 100%;
  background-image: url(assets/img/shape/shape1.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.banner-area::after {
  content: "";
  position: absolute;
  overflow: hidden;
  left: 40%;
  top: 0;
  width: 50%;
  height: 100%;
  background-image: url(assets/img/shape/shape2.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.banner-content {
  position: relative;
  z-index: 1;
}
.banner-content span {
  color: #ffffff;
}
.banner-content h1 {
  margin-top: 10px;
  font-size: 65px;
  color: #ffffff;
  font-family: "Playfair Display", serif;
  font-weight: 700;
  margin-bottom: 25px;
  line-height: 1.3;
}
.banner-content p {
  color: #ffffff;
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 400;
}
.banner-content .banner-btn .contact-btn {
  padding: 12px 30px;
  border-radius: 3px;
  background-color: #fba311;
  color: #ffffff;
  transition: 0.7s;
}
.banner-content .banner-btn .contact-btn:hover {
  background-color: #301d44;
  color: #ffffff;
}
.banner-content .banner-btn .get-btn {
  margin-left: 20px;
  padding: 12px 30px;
  border-radius: 3px;
  background-color: #301d44;
  color: #ffffff;
  transition: 0.7s;
}
.banner-content .banner-btn .get-btn:hover {
  background-color: #fba311;
  color: #ffffff;
}

.banner-img {
  padding: 100px 0;
  margin-right: 50px;
  position: relative;
}
.banner-img::before {
  content: "";
  position: absolute;
  top: 160px;
  right: -50px;
  border: 2px solid #fba311;
  border-radius: 100px 0 100px 100px;
  width: 100%;
  height: 660px;
  background-color: transparent;
}
.banner-img img {
  border-radius: 100px 0 100px 100px;
  position: relative;
  z-index: 1;
}
.banner-img .dots {
  position: absolute;
  top: 60%;
  right: -215px;
  animation: flash 7s infinite linear;
}
.banner-img .dots img {
  width: 180px;
  border-radius: 0;
}

.banner-shape .shape-icon {
  font-size: 600px;
  line-height: 1.2;
  color: #5f40c9;
  position: absolute;
  top: 50px;
  left: 20px;
}

.down-btn-area {
  float: left;
  margin-bottom: 70px;
}
.down-btn-area .down-btn {
  width: 45px;
  height: 45px;
  background-color: #8871d7;
  position: relative;
  z-index: 1;
  line-height: 45px;
  color: #ffffff;
  border-radius: 50px;
  font-size: 24px;
  text-align: center;
}
.down-btn-area .down-btn:hover {
  background-color: #fba311;
}

.banner-area-two {
  background-color: #f5f8fe;
  position: relative;
}
.banner-area-two::before {
  content: "";
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  right: 0;
  background-image: url(assets/img/home-two/shape-bg-2.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-img-two {
  position: relative;
  background-color: #5a3ac7;
}
.banner-img-two::before {
  content: "";
  position: absolute;
  top: 0;
  right: -110px;
  width: 400px;
  height: 100%;
  -webkit-transform: skew(45deg);
  transform: skew(15deg);
  background-color: #f5f8fe;
}
.banner-img-two::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url(assets/img/home-two/shape-bg-1.png);
  background-position: center center;
  background-size: cover;
}
.banner-img-two .banner-images {
  max-width: 600px;
  margin-left: auto;
  position: relative;
  z-index: 1;
  padding-top: 70px;
}
.banner-img-two .dots {
  position: absolute;
  top: 100px;
  right: 0;
  animation: flash 7s infinite linear;
}
.banner-img-two .banner-half-circle {
  position: absolute;
  bottom: 0;
  right: -80px;
  width: 400px;
  height: 200px;
  border-radius: 200px 200px 0 0;
  background-color: #ebd391;
}

.banner-two-content {
  margin-left: 40px;
}
.banner-two-content span {
  color: #fba311;
}
.banner-two-content h1 {
  color: #301d44;
  font-family: "Poppins", sans-serif;
  font-size: 60px;
}
.banner-two-content p {
  color: #301d44;
}

.banner-two-shape .shape-1 {
  position: absolute;
  top: 65px;
  left: 3%;
  animation: flash 7s infinite linear;
}
.banner-two-shape .shape-2 {
  position: absolute;
  bottom: 65px;
  left: 0;
}
.banner-two-shape .shape-3 {
  position: absolute;
  top: 65px;
  right: 3%;
  animation: rotated360 7s infinite linear;
}
.banner-two-shape .shape-4 {
  position: absolute;
  bottom: -5px;
  right: 0;
}

.banner-area-three {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(assets/img/home-three.jpg);
  position: relative;
}
.banner-area-three::before {
  content: "";
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
}
.banner-area-three::after {
  content: "";
  position: absolute;
  overflow: hidden;
  left: 20%;
  top: 0;
  width: 70%;
  height: 100%;
  background-image: url(assets/img/shape/shape5.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.banner-content-three {
  padding-top: 140px;
  padding-bottom: 215px;
  max-width: 910px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: relative;
  z-index: 1;
}
.banner-content-three span {
  color: #fba311;
}
.banner-content-three h1 {
  margin-bottom: 20px;
}
.banner-content-three p {
  max-width: 610px;
  margin-left: auto;
  margin-right: auto;
}

/*=================================
Main Banner End
====================================*/
/*================================
Inner Banner
==================================*/
.inner-banner {
  position: relative;
  background-position: center center;
  background-size: cover;
}
.inner-banner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000000;
  opacity: 0.7;
}
.inner-banner .inner-title {
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
}
.inner-banner .inner-title::before {
  content: "";
  position: absolute;
  width: 570px;
  height: 270px;
  top: 60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #301d44;
  opacity: 0.5;
  border-radius: 5px;
}
.inner-banner .inner-title h3 {
  font-size: 35px;
  color: #ffffff;
  font-weight: 500;
  font-family: "Playfair Display", serif;
  position: relative;
}
.inner-banner .inner-title ul {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}
.inner-banner .inner-title ul li {
  font-size: 18px;
  color: #ffffff;
  display: inline-block;
}
.inner-banner .inner-title ul li i {
  color: #ffffff;
  position: relative;
  top: 3px;
  margin: 0 3px;
}
.inner-banner .inner-title ul li a {
  color: #ffffff;
}
.inner-banner .inner-title ul li a:hover {
  color: #da691f;
}

.inner-bg1 {
  background-image: url(assets/img/inner-banner/inner-banner-1.jpg);
}

.inner-bg2 {
  background-image: url(assets/img/inner-banner/inner-banner-2.jpg);
}

.inner-bg3 {
  background-image: url(assets/img/inner-banner/inner-banner-3.jpg);
}

.inner-bg4 {
  background-image: url(assets/img/inner-banner/inner-banner-4.jpg);
}

.inner-bg5 {
  background-image: url(assets/img/inner-banner/inner-banner-5.jpg);
}

.inner-bg6 {
  background-image: url(assets/img/inner-banner/inner-banner-6.jpg);
}

.inner-bg7 {
  background-image: url(assets/img/inner-banner/inner-banner-7.jpg);
}

.inner-bg8 {
  background-image: url(assets/img/inner-banner/inner-banner-8.jpg);
}

.inner-bg9 {
  background-image: url(assets/img/inner-banner/inner-banner-9.jpg);
}

.inner-bg10 {
  background-image: url(assets/img/inner-banner/inner-banner-10.jpg);
}

.inner-bg11 {
  background-image: url(assets/img/inner-banner/inner-banner-11.jpg);
}

/*=================================
Status Area
====================================*/
.status-area {
  position: relative;
  margin-top: -70px;
  z-index: 1;
}

.status-bg {
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.status-card {
  padding: 40px 20px;
  position: relative;
  z-index: 1;
}
.status-card h3 {
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}
.status-card p {
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}
.status-card::before {
  content: "";
  position: absolute;
  top: 30px;
  right: -10px;
  width: 1px;
  height: 150px;
  background-color: #efedf0;
}
.status-card i {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 100px;
  color: #f9f8f9;
}
.status-card .status-bottom-1 {
  background-color: #fba311;
  width: 393px;
  height: 5px;
  left: -35px;
  bottom: -40px;
  position: relative;
}
.status-card .status-bottom-2 {
  background-color: #a49cad;
  width: 393px;
  height: 5px;
  left: -40px;
  bottom: -40px;
  position: relative;
}
.status-card .status-bottom-3 {
  background-color: #fb95a8;
  width: 393px;
  height: 5px;
  left: -40px;
  bottom: -40px;
  position: relative;
}

.status-area .col-lg-4:nth-child(3) .status-card::before {
  display: none;
}

/*=================================
Status Area End
====================================*/
/*=================================
Special Area
====================================*/
.special-area {
  background-color: #f8f9fb;
}

.special-card {
  margin-bottom: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  z-index: 1;
  border-radius: 5px;
}
.special-card:hover::before {
  opacity: 1;
  height: 100%;
}
.special-card:hover .content h3 a {
  color: #ffffff;
}
.special-card:hover .content p {
  color: #d0ced3;
}
.special-card:hover .content .learn-btn {
  background-color: #fba311;
  color: #ffffff;
}
.special-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
  background-color: #301d44;
  border-radius: 5px;
  transition: 0.7s;
  opacity: 0;
  z-index: -1;
}
.special-card a {
  display: block;
}
.special-card a img {
  border-radius: 5px;
}
.special-card .content {
  padding: 30px 20px;
}
.special-card .content h3 {
  margin-bottom: 10px;
  color: #301d44;
}
.special-card .content h3 a {
  display: block;
  color: #301d44;
}
.special-card .content p {
  margin-bottom: 20px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}
.special-card .content .learn-btn {
  display: inline-block;
  padding: 12px 30px;
  border-radius: 3px;
  background-color: #301d44;
  color: #ffffff;
  transition: 0.7s;
}
.special-card .content .learn-btn:hover {
  background-color: #fba311;
  color: #ffffff;
}

/*=================================
Special Area End
====================================*/
/*=================================
About Area
====================================*/
.about-area {
  position: relative;
}

.about-img {
  margin-left: 70px;
  margin-bottom: 90px;
  margin-right: 30px;
  position: relative;
}
.about-img::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 60px;
  left: -70px;
  border: 2px solid #fba311;
  border-radius: 0 100px 100px 100px;
  width: 100%;
  height: 570px;
  background-color: transparent;
}
.about-img .about-img-small {
  position: absolute;
  bottom: -60px;
  left: -40px;
}

.about-img-before {
  position: relative;
}
.about-img-before::before {
  border-color: #301d44;
}

.about-content {
  margin-bottom: 30px;
}
.about-content span {
  color: #fba311;
}
.about-content h2 {
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 36px;
  color: #301d44;
}
.about-content h2 b {
  color: #fba311;
  font-weight: 600;
}
.about-content h3 {
  font-size: 18px;
  color: #594a69;
  margin-bottom: 20px;
}
.about-content p {
  margin-bottom: 20px;
}
.about-content .about-counter {
  position: relative;
  margin-top: 20px;
  margin-bottom: 40px;
}
.about-content .about-counter i {
  position: absolute;
  top: 0;
  left: 0;
  width: 45px;
  height: 45px;
  line-height: 45px;
  color: #fba311;
  background-color: #fff8e5;
  border-radius: 3px;
  text-align: center;
  font-size: 26px;
  display: inline-block;
}
.about-content .about-counter .content {
  margin-left: 60px;
  display: inline-block;
}
.about-content .about-counter .content h3 {
  font-size: 20px;
  color: #301d44;
  line-height: 1;
  margin-bottom: 0;
}
.about-content .about-counter .content span {
  font-size: 14px;
  color: #301d44;
}
.about-content .about-btn .learn-btn {
  padding: 12px 30px;
  border-radius: 3px;
  background-color: #fba311;
  color: #ffffff;
  transition: 0.7s;
}
.about-content .about-btn .learn-btn:hover {
  background-color: #301d44;
}
.about-content .about-btn .get-btn {
  margin-left: 20px;
  padding: 11px 30px;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid #301d44;
  color: #301d44;
  transition: 0.7s;
}
.about-content .about-btn .get-btn:hover {
  background-color: #301d44;
  color: #ffffff;
}

.about-img-2 {
  margin-left: 70px;
  margin-bottom: 90px;
  margin-right: 30px;
  position: relative;
}
.about-img-2 .about-img-small-2 {
  position: absolute;
  bottom: -60px;
  left: -40px;
}
.about-img-2 .about-dots {
  position: absolute;
  z-index: -1;
  bottom: -80px;
  right: -100px;
  animation: flash 7s infinite linear;
}

.about-content-max {
  max-width: 580px;
  margin-right: auto;
  margin-left: 40px;
}

.about-img-3 {
  margin-bottom: 30px;
}

.about-polygon-shape {
  position: absolute;
  bottom: 10%;
  right: 5%;
}

/*=================================
About Area End
====================================*/
/*=================================
Video Area
====================================*/
.video-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 100px;
  margin-bottom: 100px;
  z-index: 1;
}
.video-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  background-color: #000000;
  opacity: 0.7;
}
.video-area::after {
  content: "";
  position: absolute;
  top: 0;
  left: 25%;
  width: 75%;
  height: 100%;
  background-image: url(assets/img/shape/shape3.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.video-bg1 {
  background-image: url(assets/img/video-bg.jpg);
}

.video-bg2 {
  background-image: url(assets/img/video-bg2.jpg);
}

.video-btn {
  margin-top: 50px;
  float: right;
}
.video-btn .video-play-btn {
  font-size: 20px;
  width: 130px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  display: inline-block;
  border-radius: 3px;
  color: #ffffff;
  background-color: #fba311;
  position: relative;
  z-index: 1;
}
.video-btn .video-play-btn:hover {
  background-color: #301d44;
}
.video-btn .video-play-btn:hover::before {
  border-color: #301d44;
}
.video-btn .video-play-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #fba311;
  border-radius: 3px;
  animation: scale 1.6s linear infinite;
}

.video-content {
  margin-left: 50px;
}
.video-content .section-title h2 {
  color: #ffffff;
  max-width: 500px;
}

.video-area-two {
  background-image: url(assets/img/solution-bg.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.video-area-two::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #301d44;
  opacity: 0.7;
}
.video-area-two::after {
  content: "";
  position: absolute;
  top: 0;
  left: 25%;
  width: 75%;
  height: 100%;
  background-image: url(assets/img/shape/shape3.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.video-btn-two {
  margin-bottom: 30px;
  text-align: center;
}
.video-btn-two .play-on-btn {
  font-size: 20px;
  width: 130px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  display: inline-block;
  border-radius: 3px;
  color: #ffffff;
  background-color: #fba311;
  position: relative;
  z-index: 1;
}
.video-btn-two .play-on-btn:hover {
  background-color: #301d44;
}
.video-btn-two .play-on-btn:hover::before {
  border-color: #301d44;
}
.video-btn-two .play-on-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #fba311;
  border-radius: 3px;
  animation: scale 1.6s linear infinite;
}

.video-content-two {
  position: relative;
  text-align: center;
}
.video-content-two .section-title h2 {
  color: #ffffff;
  max-width: 610px;
  margin: 0 auto;
}

/*=================================
Video Area End
====================================*/
.guidelines-area {
  background-color: #ffffff;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  bottom: -100px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.guidelines-max {
  max-width: 1200px;
  margin-left: auto;
}

.guidelines-bg {
  position: relative;
}
.guidelines-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-image: url(assets/img/guidelines-profile.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.guidelines-bg2 {
  position: relative;
}
.guidelines-bg2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-image: url(assets/img/guidelines-profile-2.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.guidelines-content {
  padding: 70px 0 70px 60px;
  position: relative;
  z-index: 1;
}
.guidelines-content h2 {
  font-size: 36px;
  margin-bottom: 15px;
}
.guidelines-content p {
  margin-bottom: 20px;
}
.guidelines-content .signature {
  margin-bottom: 10px;
}
.guidelines-content .content h3 {
  font-size: 22px;
  margin-bottom: 5px;
}
.guidelines-content .content span {
  color: #301d44;
}

.guidelines-img {
  margin-right: -200px;
}

.guidelines-content-two {
  padding: 70px 0 70px 60px;
  position: relative;
  z-index: 1;
  max-width: 500px;
}
.guidelines-content-two h2 {
  font-size: 36px;
  margin-bottom: 15px;
  color: #ffffff;
}
.guidelines-content-two p {
  margin-bottom: 25px;
  color: #a49cad;
}
.guidelines-content-two .signature {
  margin-bottom: 15px;
}
.guidelines-content-two .content h3 {
  font-size: 22px;
  margin-bottom: 5px;
  color: #ffffff;
}
.guidelines-content-two .content span {
  color: #a49cad;
}

.guidelines-content-bg {
  background-color: #301d44;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}
.guidelines-content-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 75%;
  height: 100%;
  border-radius: 5px;
  background-image: url(assets/img/shape/shape4.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

/*=================================
Service Area
====================================*/
.services-area {
  position: relative;
}

.service-text {
  margin-top: 50px;
  margin-bottom: 45px;
}

.services-card {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  padding: 30px 15px;
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-align: center;
}
.services-card::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 0;
  background-image: url(assets/img/services/services-img1.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 5px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: 0.7s;
}
.services-card::after {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 5px;
  bottom: 0;
  width: 100%;
  height: 0;
  left: 0;
  right: 0;
  background-color: #301d44;
  opacity: 0;
  transition: 0.8s;
}
.services-card .services-card-bottom {
  position: absolute;
  bottom: 0;
  width: 0;
  background-color: #fba311;
  opacity: 0;
  transition: 0.7s;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 3px;
  border-radius: 5px;
}
.services-card:hover::before {
  opacity: 1;
  height: 100%;
}
.services-card:hover::after {
  opacity: 0.8;
  height: 100%;
  bottom: 0;
}
.services-card:hover h3 a {
  color: #ffffff;
}
.services-card:hover p {
  color: #cfcbcb;
}
.services-card:hover .services-more {
  background-color: #fba311;
}
.services-card:hover .services-card-bottom {
  opacity: 1;
  width: 80%;
}
.services-card .services-icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  color: #fba311;
  font-size: 30px;
  background-color: transparent;
  border: 1px solid #fba311;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
}
.services-card h3 {
  margin-bottom: 10px;
}
.services-card h3 a {
  color: #301d44;
  display: block;
}
.services-card p {
  margin-bottom: 15px;
}
.services-card .services-more {
  height: 30px;
  line-height: 30px;
  width: 60px;
  font-size: 14px;
  background-color: #301d44;
  color: #ffffff;
  border-radius: 20px;
}
.services-card .services-more:hover {
  background-color: #5a3ac7;
}

.service-view-btn {
  margin-bottom: 30px;
}
.service-view-btn .view-btn {
  padding: 11px 35px;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid #301d44;
  color: #301d44;
  transition: 0.7s;
}
.service-view-btn .view-btn:hover {
  background-color: #301d44;
  color: #ffffff;
}

.service-area-two .section-title {
  max-width: 785px;
  margin-left: auto;
  margin-right: auto;
}

.service-item {
  border-radius: 5px;
  position: relative;
  margin-bottom: 30px;
}
.service-item:hover::before {
  height: 100%;
  opacity: 1;
}
.service-item:hover::after {
  width: 80%;
  opacity: 1;
}
.service-item:hover .images .service-item-icon {
  background-color: #ffffff;
  color: #fba311;
}
.service-item:hover .images .service-item-more {
  opacity: 1;
}
.service-item:hover .content {
  border-color: #301d44;
}
.service-item:hover .content h3 a {
  color: #ffffff;
}
.service-item:hover .content p {
  color: #d0ced3;
}
.service-item::before {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 5px;
  top: 0;
  width: 100%;
  height: 0;
  left: 0;
  right: 0;
  background-color: #301d44;
  opacity: 0;
  transition: 0.8s;
}
.service-item::after {
  content: "";
  position: absolute;
  border-radius: 5px;
  bottom: 0;
  width: 0;
  background-color: #fba311;
  opacity: 0;
  transition: 0.7s;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 3px;
  border-radius: 5px;
}
.service-item .images {
  position: relative;
}
.service-item .images a {
  display: block;
}
.service-item .images a img {
  border-radius: 5px;
}
.service-item .images .service-item-icon {
  position: absolute;
  bottom: -20px;
  left: 20px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  color: #ffffff;
  font-size: 30px;
  background-color: #fba311;
  border-radius: 5px;
  text-align: center;
}
.service-item .images .service-item-more {
  position: absolute;
  bottom: -15px;
  right: 20px;
  height: 30px;
  line-height: 30px;
  width: 60px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  background-color: #fba311;
  color: #ffffff;
  border-radius: 20px;
  opacity: 0;
  transition: 0.7s;
}
.service-item .images .service-item-more:hover {
  background-color: #5a3ac7;
}
.service-item .content {
  padding: 40px 20px 20px;
  border: 1px dashed #d6d2da;
  border-radius: 0 0 5px 5px;
}
.service-item .content h3 {
  margin-bottom: 10px;
}
.service-item .content h3 a {
  color: #301d44;
}
.service-item .content p {
  margin-bottom: 0;
}

.service-shape .shape1 {
  position: absolute;
  top: 30%;
  left: 7%;
}
.service-shape .shape2 {
  position: absolute;
  top: 10%;
  left: -5%;
  z-index: -1;
}
.service-shape .shape3 {
  position: absolute;
  bottom: 20%;
  left: 0%;
  z-index: -1;
}

.services-card-bg {
  position: relative;
}
.services-card-bg::before {
  content: "";
  position: absolute;
  top: auto;
  bottom: 0;
  width: 100%;
  height: 0;
  background-image: none;
  background-repeat: no-repeat;
  border-radius: 5px;
  left: auto;
  right: 0;
  opacity: 0;
  transition: 0.7s;
}
.services-card-bg::after {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 5px;
  bottom: 0;
  width: 100%;
  height: 0;
  left: 0;
  right: 0;
  background-color: #301d44;
  opacity: 0;
  transition: 0.8s;
}
.services-card-bg:hover::before {
  opacity: 0;
  height: 100%;
}
.services-card-bg:hover::after {
  height: 100%;
  bottom: 0;
  opacity: 1;
}

.service-widget-title .section-title {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.services-details-content img {
  margin-bottom: 30px;
}
.services-details-content .services-details-card {
  margin-bottom: 30px;
}
.services-details-content .services-details-card h3 {
  margin-bottom: 10px;
}
.services-details-content .services-details-card p {
  margin-bottom: 0;
  font-size: 15px;
}
.services-details-content .services-item-area {
  margin-top: 30px;
}
.services-details-content .services-item-area .services-details-item {
  text-align: center;
  margin-bottom: 30px;
}
.services-details-content .services-item-area .services-details-item i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  color: #fba311;
  font-size: 30px;
  background-color: transparent;
  border: 1px solid #fba311;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  display: inline-block;
}
.services-details-content .services-item-area .services-details-item h3 {
  margin-bottom: 10px;
}
.services-details-content .service-advantage .advantage-item {
  margin-bottom: 30px;
}
.services-details-content .service-advantage .advantage-item h3 {
  margin-bottom: 10px;
}
.services-details-content .service-advantage .advantage-item p {
  margin-bottom: 20px;
}
.services-details-content .service-advantage .advantage-item ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.services-details-content .service-advantage .advantage-item ul li {
  display: block;
  color: #301d44;
}
.services-details-content .service-advantage .advantage-item ul li i {
  color: #fba311;
  font-size: 24px;
  position: relative;
  top: 3px;
}
.services-details-content .service-advantage .service-content {
  background-color: #301d44;
  padding: 50px 30px;
  border-radius: 5px;
  margin-bottom: 30px;
}
.services-details-content .service-advantage .service-content h3 {
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 15px;
}
.services-details-content .service-advantage .service-content h2 {
  color: #ffffff;
  font-size: 26px;
  margin-bottom: 25px;
}
.services-details-content .service-advantage .service-content .default-btn {
  padding: 10px 26px;
  font-size: 15px;
  border-radius: 0;
}

.services-side-bar {
  margin-bottom: 30px;
}

.services-bar-widget {
  margin-bottom: 35px;
}
.services-bar-widget .title {
  font-size: 20px;
  color: #301d44;
  padding: 0 30px 15px;
  font-weight: 600;
}
.services-bar-widget .services-bar-categories {
  padding: 0 20px;
}
.services-bar-widget .services-bar-categories ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.services-bar-widget .services-bar-categories ul li {
  position: relative;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  background-color: #fbfbfb;
  padding: 12px 20px;
  border-radius: 5px;
}
.services-bar-widget .services-bar-categories ul li:hover {
  background-color: #301d44;
}
.services-bar-widget .services-bar-categories ul li:hover a {
  color: #ffffff;
}
.services-bar-widget .services-bar-categories ul li i {
  color: #fba311;
  margin-right: 15px;
}
.services-bar-widget .services-bar-categories ul li a {
  display: inline-block;
  color: #301d44;
  font-weight: normal;
  font-weight: 600;
}
.services-bar-widget .services-bar-widget-tag {
  list-style: none;
  margin: 0;
  padding: 10px 20px 5px;
}
.services-bar-widget .services-bar-widget-tag li {
  display: inline-block;
  padding: 7px 15px;
  margin: 5px 2px;
  transition: 0.7s;
  color: #301d44;
  font-size: 14px;
  font-weight: 500;
  border: 1px dashed #fba311;
}
.services-bar-widget .services-bar-widget-tag li:hover {
  background-color: #fba311;
}
.services-bar-widget .services-bar-widget-tag li a {
  color: #301d44;
}
.services-bar-widget .services-bar-widget-tag li:hover a {
  color: #ffffff;
}
.services-bar-widget .services-bar-contact {
  margin-bottom: 30px;
  background-color: #301d44;
  position: relative;
  padding: 50px;
  text-align: center;
  border-radius: 3px;
}
.services-bar-widget .services-bar-contact i {
  font-size: 60px;
  color: #ffffff;
  position: relative;
  z-index: 1;
}
.services-bar-widget .services-bar-contact h3 {
  font-size: 28px;
  color: #ffffff;
  font-weight: 400;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}
.services-bar-widget .services-bar-contact span {
  font-size: 22px;
  position: relative;
  z-index: 1;
}
.services-bar-widget .services-bar-contact span a {
  display: block;
  color: #fba311;
}
.services-bar-widget .services-bar-contact span a:hover {
  color: #ffffff;
}

/*=================================
Service Area End
====================================*/
/*=================================
Choose Area
====================================*/
.choose-area {
  background-color: #f9f9f9;
}
.choose-area .section-title h2 {
  max-width: 540px;
}

.choose-tab {
  margin-top: 30px;
}
.choose-tab .tabs {
  margin: 0;
  padding: 0;
  list-style: none;
}
.choose-tab .tabs li {
  display: inline-block;
  line-height: initial;
  margin-right: 25px;
}
.choose-tab .tabs li a {
  display: inline-block;
  position: relative;
  padding: 16px 30px;
  border-radius: 5px;
  font-weight: 600;
  color: #301d44;
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.choose-tab .tabs li a:focus {
  color: #ffffff;
  background-color: #fba311;
}
.choose-tab .tabs li.active a {
  color: #ffffff;
  background-color: #fba311;
}
.choose-tab .tabs li.current a {
  color: #ffffff;
  background-color: #fba311;
}

.tab .tabs_item {
  display: none;
}
.tab .tabs_item:first-child {
  display: block;
}

.choose-item .choose-item-img {
  margin-bottom: 30px;
}
.choose-item .choose-item-img img {
  border-radius: 5px;
}
.choose-item .choose-item-content p {
  margin-bottom: 30px;
}
.choose-item .choose-item-list {
  margin-bottom: 30px;
}
.choose-item .choose-item-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.choose-item .choose-item-list ul li {
  display: block;
  font-weight: 500;
  margin-bottom: 7px;
  transition: 0.7s;
}
.choose-item .choose-item-list ul li:hover {
  color: #fba311;
}
.choose-item .choose-item-list ul li:hover i {
  background-color: #301d44;
  color: #ffffff;
}
.choose-item .choose-item-list ul li:last-child {
  margin-bottom: 0;
}
.choose-item .choose-item-list ul li i {
  font-size: 14px;
  color: #301d44;
  width: 24px;
  height: 24px;
  line-height: 22px;
  border-radius: 50px;
  border: 1px solid #301d44;
  text-align: center;
  margin-right: 7px;
  transition: 0.7s;
}

.choose-img {
  position: relative;
  margin-left: 50px;
  margin-bottom: 80px;
}
.choose-img img {
  border-radius: 5px;
}
.choose-img .images {
  position: absolute;
  bottom: -50px;
  left: -50px;
}
.choose-img .images img {
  border-radius: 5px;
}

.choose-content {
  margin-bottom: 30px;
}
.choose-content .section-title {
  margin-bottom: 40px;
}
.choose-content .section-title p {
  margin-bottom: 30px;
}

.choose-content-list {
  position: relative;
  margin-top: 30px;
}
.choose-content-list i {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #fba311;
  font-size: 24px;
  background-color: transparent;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #fba311;
}
.choose-content-list .content {
  margin-left: 75px;
}
.choose-content-list .content h3 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
}
.choose-content-list .content p {
  margin-bottom: 0;
}

.choose-img-2 {
  margin-bottom: 30px;
  margin-left: 45px;
  position: relative;
  z-index: 1;
}
.choose-img-2 .choose-dots {
  position: absolute;
  bottom: -45px;
  right: -70px;
  z-index: -1;
}

.choose-content-bg {
  background-color: #301d44;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  padding: 70px 40px;
}
.choose-content-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 75%;
  height: 70%;
  border-radius: 5px;
  background-image: url(assets/img/shape/shape9.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.choose-content-bg .section-title h2 {
  color: #ffffff;
  max-width: 520px;
}
.choose-content-bg .section-title p {
  color: #c1bcc7;
}

.choose-content-list-color .content h3 {
  color: #ffffff;
}
.choose-content-list-color .content p {
  color: #c1bcc7;
}

.choose-max {
  max-width: 680px;
  margin-left: auto;
  margin-right: 40px;
}

.choose-img-3 {
  margin-left: -400px;
  z-index: -1;
  margin-bottom: 30px;
}

.choose-bg {
  background-color: #fbfbfb;
}

.choose-content-title .section-title h2 {
  max-width: 520px;
}
.choose-content-title .book-btn {
  padding: 12px 27px;
  background-color: transparent;
  color: #fba311;
  border-radius: 5px;
  position: relative;
  border: 1px solid #fba311;
  z-index: 1;
}
.choose-content-title .book-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
  background-color: #5a3ac7;
  opacity: 0;
  transition: 0.7s;
  border-radius: 5px;
}
.choose-content-title .book-btn:hover {
  background-color: #5a3ac7;
  color: #ffffff;
  border-color: #5a3ac7;
}
.choose-content-title .book-btn:hover::before {
  height: 100%;
  opacity: 1;
  border-radius: 5px;
  border-color: #5a3ac7;
}

/*=================================
Choose Area End
====================================*/
.case-card {
  margin-bottom: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  z-index: 1;
  border-radius: 5px;
}
.case-card:hover::before {
  opacity: 1;
  height: 100%;
}
.case-card:hover .content h3 a {
  color: #ffffff;
}
.case-card:hover .content p {
  color: #d0ced3;
}
.case-card:hover .content .learn-btn {
  background-color: #fba311;
  color: #ffffff;
}
.case-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
  background-color: #301d44;
  border-radius: 5px;
  transition: 0.7s;
  opacity: 0;
  z-index: -1;
}
.case-card a {
  display: block;
}
.case-card a img {
  border-radius: 5px;
}
.case-card .content {
  padding: 30px 20px;
}
.case-card .content h3 {
  margin-bottom: 10px;
  color: #301d44;
}
.case-card .content h3 a {
  display: block;
  color: #301d44;
}
.case-card .content p {
  margin-bottom: 20px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}
.case-card .content .case-more {
  height: 30px;
  line-height: 30px;
  width: 60px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  background-color: #fba311;
  color: #ffffff;
  border-radius: 20px;
  transition: 0.7s;
}
.case-card .content .case-more:hover {
  background-color: #5a3ac7;
  color: #ffffff;
}

/*=================================
Solutions Area
====================================*/
.solutions-area {
  background-image: url(assets/img/solution-bg.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.solutions-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #301d44;
  opacity: 0.8;
}

.solutions-content {
  margin-bottom: 30px;
}
.solutions-content .section-title h2 {
  color: #ffffff;
  max-width: 520px;
}

.solutions-btn {
  margin-bottom: 30px;
}

/*=================================
Solutions Area End
====================================*/
/*=================================
Project Area
====================================*/
.project-title {
  margin-bottom: 30px;
}

.project-title-two {
  margin-bottom: 30px;
}
.project-title-two .section-title {
  text-align: center;
}
.project-title-two .section-title h2 {
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
}
.project-title-two .section-title p {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}

.project-text {
  margin-top: 50px;
  margin-bottom: 30px;
}
.project-text p {
  margin-bottom: 0;
}

.project-tab {
  margin-top: 20px;
}
.project-tab .tabs {
  margin: 0;
  padding: 0;
  list-style: none;
}
.project-tab .tabs li {
  display: inline-block;
  line-height: initial;
  margin-right: 25px;
}
.project-tab .tabs li a {
  display: inline-block;
  position: relative;
  font-size: 17px;
  font-weight: 600;
  color: #301d44;
}
.project-tab .tabs li a:focus {
  color: #fba311;
}
.project-tab .tabs li.active a {
  color: #fba311;
}
.project-tab .tabs li.current a {
  color: #fba311;
}

.project-card {
  margin-bottom: 30px;
  border-radius: 5px;
  position: relative;
  text-align: center;
  overflow: hidden;
}
.project-card:hover .project-content {
  padding: 20px;
  height: auto;
  width: 100%;
  background-color: #301d44;
  bottom: 0;
}
.project-card:hover .project-content .content {
  opacity: 1;
}
.project-card:hover .project-card-bottom {
  width: 80%;
  opacity: 1;
}
.project-card:hover .project-content-bg {
  background-color: #ffffff;
}
.project-card a {
  display: block;
}
.project-card a img {
  border-radius: 5px;
}
.project-card .project-content {
  position: absolute;
  bottom: -150px;
  left: 0;
  right: 0;
  background-color: rgba(48, 29, 68, 0.6705882353);
  padding: 10px 20px;
  height: auto;
  border-radius: 5px;
  transition: 0.9s;
  overflow: hidden;
}
.project-card .project-content h3 {
  font-weight: 400;
  margin-bottom: 0;
}
.project-card .project-content h3 a {
  color: #ffffff;
}
.project-card .project-content .content {
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0;
}
.project-card .project-content .content p {
  color: #ffffff;
  margin-bottom: 15px;
}
.project-card .project-content .content .project-more {
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin: 0 auto;
  width: 60px;
  font-size: 14px;
  background-color: #fba311;
  color: #ffffff;
  border-radius: 20px;
}
.project-card .project-content .content .project-more:hover {
  background-color: #5a3ac7;
}
.project-card .project-card-bottom {
  position: absolute;
  bottom: 0;
  width: 0;
  background-color: #fba311;
  opacity: 0;
  transition: 0.7s;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 3px;
  border-radius: 5px;
}
.project-card .project-content-bg {
  background-color: rgba(255, 255, 255, 0.8588235294);
}
.project-card .project-content-bg h3 {
  font-weight: 600;
}
.project-card .project-content-bg h3 a {
  color: #301d44;
}
.project-card .project-content-bg .content p {
  color: #301d44;
}

.project-view-btn {
  margin-top: 20px;
  margin-bottom: 30px;
}
.project-view-btn .view-btn {
  padding: 11px 35px;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid #301d44;
  color: #301d44;
  transition: 0.7s;
}
.project-view-btn .view-btn:hover {
  background-color: #301d44;
  color: #ffffff;
}

.project-article {
  margin-bottom: 30px;
}
.project-article .blog-article-img {
  margin-bottom: 30px;
}
.project-article .project-status {
  padding-bottom: 20px;
  border-bottom: 3px solid #fba311;
  margin-bottom: 30px;
}
.project-article .project-status ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.project-article .project-status ul li {
  display: inline-block;
  color: #301d44;
  margin-right: 30px;
  position: relative;
}
.project-article .project-status ul li::before {
  content: "";
  position: absolute;
  top: 3px;
  width: 1px;
  height: 20px;
  background-color: #e0dde3;
  right: -20px;
}
.project-article .project-status ul li:last-child {
  margin-right: 0;
}
.project-article .project-status ul li:last-child::before {
  display: none;
}
.project-article .project-status ul li a {
  color: #fba311;
  display: inline-block;
}
.project-article .project-status ul li a:hover {
  color: #5a3ac7;
}
.project-article .project-status .project-comment {
  float: right;
}
.project-article .project-status .project-comment h3 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
.project-article .project-status .project-comment h3 i {
  color: #fba311;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.project-article .project-content h2 {
  font-size: 26px;
  margin-bottom: 20px;
  max-width: 650px;
}
.project-article .project-content p {
  margin-bottom: 20px;
}
.project-article .project-another-content p {
  margin-bottom: 20px;
}
.project-article .project-another-content .content-img {
  margin-top: 30px;
}
.project-article .project-another-content .content-img img {
  margin-bottom: 30px;
}
.project-article .project-article-share {
  margin-top: 30px;
  border-bottom: 1px solid #e0dde3;
  padding-bottom: 30px;
}
.project-article .project-article-share .social-icon {
  list-style: none;
  margin: 0;
  padding: 0;
}
.project-article .project-article-share .social-icon li {
  display: inline-block;
  margin-right: 5px;
  color: #301d44;
}
.project-article .project-article-share .social-icon li:first-child {
  margin-right: 15px;
  font-weight: 600;
}
.project-article .project-article-share .social-icon li a {
  width: 30px;
  height: 30px;
  line-height: 32px;
  text-align: center;
  background-color: #fba311;
  color: #ffffff;
  border-radius: 3px;
}
.project-article .project-article-share .social-icon li a:hover {
  background-color: #301d44;
  color: #ffffff;
}

/*=================================
Project Area End
====================================*/
/*=================================
Blog Area
====================================*/
.blog-area .section-title h2 {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.blog-area .section-title p {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.blog-card {
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  border-radius: 5px;
}
.blog-card:hover::before {
  height: 100%;
  opacity: 1;
}
.blog-card:hover::after {
  width: 90%;
  opacity: 1;
  margin: 0 auto;
}
.blog-card:hover .content h3 a {
  color: #ffffff;
}
.blog-card:hover .content ul li {
  color: #c1bcc7;
}
.blog-card:hover .content p {
  color: #c1bcc7;
}
.blog-card:hover .content .blog-more {
  background-color: #fba311;
}
.blog-card::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 0;
  background-color: #301d44;
  border-radius: 5px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: 0.7s;
}
.blog-card::after {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 5px;
  bottom: 0;
  width: 0;
  height: 3px;
  left: 0;
  right: 0;
  background-color: #fba311;
  opacity: 0;
  transition: 0.8s;
}
.blog-card a {
  display: block;
}
.blog-card a img {
  border-radius: 5px;
}
.blog-card .content {
  padding: 30px;
}
.blog-card .content h3 {
  font-size: 20px;
}
.blog-card .content h3 a {
  color: #301d44;
  margin-bottom: 10px;
}
.blog-card .content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.blog-card .content ul li {
  display: inline-block;
  font-size: 14px;
  color: #4a686a;
}
.blog-card .content ul li span {
  color: #fba311;
}
.blog-card .content ul li a {
  color: #fba311;
  display: inline-block;
}
.blog-card .content ul li a:hover {
  color: #ffffff;
}
.blog-card .content p {
  margin-top: 15px;
  margin-bottom: 20px;
}
.blog-card .content .blog-more {
  height: 30px;
  line-height: 30px;
  width: 60px;
  font-size: 14px;
  background-color: #301d44;
  color: #ffffff;
  border-radius: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.blog-card .content .blog-more:hover {
  background-color: #5a3ac7;
}

.blog-article .blog-article-img {
  margin-bottom: 30px;
}
.blog-article .blog-status {
  padding-bottom: 20px;
  border-bottom: 3px solid #fba311;
  margin-bottom: 30px;
}
.blog-article .blog-status ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.blog-article .blog-status ul li {
  display: inline-block;
  color: #301d44;
  margin-right: 30px;
  position: relative;
}
.blog-article .blog-status ul li::before {
  content: "";
  position: absolute;
  top: 3px;
  width: 1px;
  height: 20px;
  background-color: #e0dde3;
  right: -20px;
}
.blog-article .blog-status ul li:last-child {
  margin-right: 0;
}
.blog-article .blog-status ul li:last-child::before {
  display: none;
}
.blog-article .blog-status ul li a {
  color: #fba311;
  display: inline-block;
}
.blog-article .blog-status ul li a:hover {
  color: #5a3ac7;
}
.blog-article .blog-status .blog-comment {
  float: right;
}
.blog-article .blog-status .blog-comment h3 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
.blog-article .blog-status .blog-comment h3 i {
  color: #fba311;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.blog-article .article-content h2 {
  font-size: 26px;
  margin-bottom: 20px;
  max-width: 500px;
}
.blog-article .article-content p {
  margin-bottom: 20px;
}
.blog-article .article-content .blockquote {
  position: relative;
  margin-bottom: 40px;
  margin-top: 40px;
  background-color: #ffffff;
  padding: 30px;
  border: 1px solid #301d44;
}
.blog-article .article-content .blockquote::before {
  content: "\f10b";
  position: absolute;
  top: -40px;
  left: 30px;
  text-align: center;
  font-size: 50px;
  font-weight: 400;
  color: #fba311;
  font-family: Flaticon;
}
.blog-article .article-content .blockquote p {
  font-size: 20px;
  color: #301d44;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 0;
}
.blog-article .another-content p {
  margin-bottom: 20px;
}
.blog-article .another-content .content-img {
  margin-top: 30px;
  margin-bottom: 30px;
}
.blog-article .blog-article-share {
  margin-top: 30px;
  border-bottom: 1px solid #e0dde3;
  padding-bottom: 30px;
}
.blog-article .blog-article-share .social-icon {
  list-style: none;
  margin: 0;
  padding: 0;
}
.blog-article .blog-article-share .social-icon li {
  display: inline-block;
  margin-right: 5px;
  color: #301d44;
}
.blog-article .blog-article-share .social-icon li:first-child {
  margin-right: 15px;
  font-weight: 600;
}
.blog-article .blog-article-share .social-icon li a {
  width: 30px;
  height: 30px;
  line-height: 32px;
  text-align: center;
  background-color: #fba311;
  color: #ffffff;
  border-radius: 3px;
}
.blog-article .blog-article-share .social-icon li a:hover {
  background-color: #301d44;
  color: #ffffff;
}
.blog-article .comments-wrap {
  margin-top: 20px;
  padding-top: 20px;
}
.blog-article .comments-wrap .title {
  font-size: 24px;
  margin-bottom: 30px;
  color: #301d44;
  font-weight: 500;
}
.blog-article .comments-wrap ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.blog-article .comments-wrap ul li {
  position: relative;
  padding: 0 30px 30px;
  padding-left: 140px;
  margin-bottom: 30px;
  border-bottom: 1px solid #eeeeee;
}
.blog-article .comments-wrap ul li:last-child {
  margin-bottom: 0;
}
.blog-article .comments-wrap ul li img {
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 30px;
}
.blog-article .comments-wrap ul li h3 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
}
.blog-article .comments-wrap ul li span {
  margin-bottom: 10px;
  display: block;
}
.blog-article .comments-wrap ul li p {
  margin-bottom: 0;
}
.blog-article .comments-wrap ul li a {
  position: absolute;
  top: 27px;
  left: 300px;
  color: #fba311;
  font-weight: 500;
}
.blog-article .comments-wrap ul li a:hover {
  color: #301d44;
}
.blog-article .comments-form .contact-form {
  padding: 35px 0 35px 0;
}
.blog-article .comments-form .contact-form .form-group .form-control {
  border-color: #988ea1;
  background-color: transparent;
  border-radius: 0;
}
.blog-article .comments-form .contact-form .default-btn {
  background-color: #301d44;
  border-radius: 0;
}

.side-bar-widget {
  margin-bottom: 30px;
}

.search-widget {
  margin-bottom: 35px;
}
.search-widget .search-form {
  position: relative;
}
.search-widget .search-form .form-control {
  height: 50px;
  border: 1px solid #988ea1;
  background-color: #ffffff;
  padding: 10px 20px;
  width: 100%;
  border-radius: 0;
}
.search-widget .search-form .form-control:focus {
  box-shadow: none;
  outline: 0;
}
.search-widget .search-form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  background-color: #fba311;
  transition: all 0.5s;
  border: none;
  outline: none;
}
.search-widget .search-form button i {
  color: #ffffff;
}
.search-widget .search-form button:hover {
  background-color: #301d44;
}

.side-bar-widget {
  margin-bottom: 35px;
  border: 1px solid #988ea1;
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(102, 102, 102, 0.1);
}
.side-bar-widget .title {
  font-size: 20px;
  color: #301d44;
  padding: 15px 0;
  font-weight: 600;
  position: relative;
  display: inline-block;
  margin-left: 30px;
  border-bottom: 3px solid #fba311;
}
.side-bar-widget .side-bar-categories {
  padding: 10px 15px 20px;
}
.side-bar-widget .side-bar-categories ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.side-bar-widget .side-bar-categories ul li {
  position: relative;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #ededed;
}
.side-bar-widget .side-bar-categories ul li a {
  display: inline-block;
  color: #301d44;
  font-weight: normal;
  padding: 7px 20px;
  font-weight: 500;
}
.side-bar-widget .side-bar-categories ul li a:hover {
  color: #fba311;
}
.side-bar-widget .side-bar-categories ul li span {
  padding: 7px 15px;
  float: right;
  color: #fba311;
  font-weight: 500;
}
.side-bar-widget .widget-popular-post {
  position: relative;
  overflow: hidden;
  padding: 20px 30px 30px;
}
.side-bar-widget .widget-popular-post .item {
  overflow: hidden;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.side-bar-widget .widget-popular-post .item:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}
.side-bar-widget .widget-popular-post .item .thumb {
  float: left;
  overflow: hidden;
  position: relative;
  margin-right: 15px;
}
.side-bar-widget .widget-popular-post .item .thumb .full-image {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
  position: relative;
  background-color: #301d44;
}
.side-bar-widget .widget-popular-post .item .thumb .full-image.bg1 {
  background-image: url(assets/img/blog/blog-img1.jpg);
}
.side-bar-widget .widget-popular-post .item .thumb .full-image.bg2 {
  background-image: url(assets/img/blog/blog-img2.jpg);
}
.side-bar-widget .widget-popular-post .item .thumb .full-image.bg3 {
  background-image: url(assets/img/blog/blog-img3.jpg);
}
.side-bar-widget .widget-popular-post .item .info {
  overflow: hidden;
}
.side-bar-widget .widget-popular-post .item .info .title-text {
  margin-bottom: 5px;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 500;
}
.side-bar-widget .widget-popular-post .item .info .title-text a {
  display: inline-block;
  color: #301d44;
}
.side-bar-widget .widget-popular-post .item .info .title-text a:hover {
  color: #fba311;
}
.side-bar-widget .widget-popular-post .item .info p {
  font-size: 14px;
  margin-bottom: 0;
  max-width: 180px;
}
.side-bar-widget .side-bar-widget-tag {
  list-style: none;
  margin: 0;
  padding: 10px 20px 20px;
}
.side-bar-widget .side-bar-widget-tag li {
  display: inline-block;
  padding: 7px 15px;
  margin: 5px;
  transition: 0.7s;
  color: #301d44;
  font-size: 14px;
  font-weight: 600;
  background-color: #ececec;
}
.side-bar-widget .side-bar-widget-tag li:hover {
  background-color: #fba311;
}
.side-bar-widget .side-bar-widget-tag li a {
  color: #301d44;
}
.side-bar-widget .side-bar-widget-tag li:hover a {
  color: #ffffff;
}

/*=================================
Blog Area End
====================================*/
/*=================================
Team Area
====================================*/
.team-area .section-title {
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
}

.team-card {
  margin-bottom: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.team-card:hover .team-img img {
  filter: brightness(0.5);
}
.team-card:hover .team-img .social-icon .social-link li a {
  transform: scaleY(1);
}
.team-card .team-img {
  position: relative;
}
.team-card .team-img img {
  border-radius: 5px;
}
.team-card .team-img .social-icon {
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  margin: 0 auto;
  bottom: 40px;
}
.team-card .team-img .social-icon .social-link {
  margin: 0;
  padding: 0;
  list-style: none;
}
.team-card .team-img .social-icon .social-link li {
  display: inline-block;
  margin-right: 5px;
}
.team-card .team-img .social-icon .social-link li a {
  border-radius: 3px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  width: 35px;
  height: 35px;
  margin: 0 auto;
  line-height: 37px;
  text-align: center;
  transform: scaleY(0);
  color: #301d44;
  background-color: #ffffff;
  transition: 0.7s;
}
.team-card .team-img .social-icon .social-link li a:hover {
  background-color: #fba311;
  color: #ffffff;
  border-radius: 50px;
}
.team-card .content {
  padding: 30px 20px;
  position: relative;
}
.team-card .content::before {
  content: "";
  position: absolute;
  background-color: #fba311;
  width: 90%;
  margin: 0 auto;
  height: 3px;
  border-radius: 5px;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}
.team-card .content h3 {
  font-size: 20px;
  margin-bottom: 5px;
}
.team-card .content span {
  color: #6e607c;
}
.team-card .content p {
  color: #6e607c;
  margin-top: 10px;
  margin-bottom: 0;
}

.team-view-btn {
  margin-top: 50px;
  margin-bottom: 30px;
}
.team-view-btn .view-btn {
  padding: 11px 35px;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid #301d44;
  color: #301d44;
  transition: 0.7s;
}
.team-view-btn .view-btn:hover {
  background-color: #301d44;
  color: #ffffff;
}

/*=================================
Team Area End
====================================*/
/*=================================
Client Area
====================================*/
.client-area {
  padding-bottom: 270px;
  background-image: url(assets/img/client/client-bg.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.client-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #301d44;
  opacity: 0.7;
}

.client-title {
  margin-bottom: 45px;
}
.client-title .section-title h2 {
  color: #ffffff;
}

.client-text {
  margin-top: 50px;
  margin-bottom: 45px;
}
.client-text p {
  color: #ffffff;
  margin-bottom: 0;
}

.client-item {
  margin-top: 45px;
  padding: 60px 20px 10px 20px;
  position: relative;
  z-index: 1;
  text-align: center;
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 30px;
}
.client-item:hover::before {
  opacity: 1;
  height: 100%;
}
.client-item:hover::after {
  opacity: 1;
  width: 100%;
}
.client-item:hover .content h3 {
  color: #ffffff;
}
.client-item:hover .content span {
  color: #d0ced3;
}
.client-item:hover .content p {
  color: #d0ced3;
}
.client-item:hover .content .quote {
  color: #fba311;
}
.client-item::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
  border-radius: 5px;
  background-color: #301d44;
  opacity: 0;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  transition: 0.7s;
}
.client-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 0;
  height: 100%;
  background-color: transparent;
  opacity: 0;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  border-radius: 5px;
  transition: 0.7s;
}
.client-item .client-img {
  position: absolute;
  top: -45px;
  left: 0;
  right: 0;
  z-index: 1;
}
.client-item .client-img img {
  border-radius: 50%;
  border: 2px solid #fba311;
}
.client-item .content h3 {
  font-size: 20px;
  margin-bottom: 0;
}
.client-item .content span {
  font-size: 14px;
  margin-bottom: 15px;
}
.client-item .content ul {
  list-style: none;
  margin: 5px 0;
  padding: 0;
}
.client-item .content ul li {
  display: inline-block;
  font-size: 15px;
  color: #fba311;
}
.client-item .content p {
  margin-bottom: 0;
}
.client-item .content .quote {
  font-size: 35px;
  color: #301d44;
  margin-bottom: 0;
}

.client-area-two {
  background-color: #f8f9fb;
  position: relative;
}
.client-area-two .section-title {
  position: relative;
  z-index: 1;
}
.client-area-two .section-title h2 {
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
}
.client-area-two .section-title p {
  max-width: 600px;
  margin: 0 auto;
}
.client-area-two .owl-dots {
  margin-top: 0px !important;
  margin-bottom: 0 !important;
}
.client-area-two .owl-dots .owl-dot span {
  background-color: #fba311 !important;
  width: 10px !important;
  height: 10px !important;
  transition: 0.7s;
}
.client-area-two .owl-dots .owl-dot.active span {
  background-color: #fba311 !important;
  width: 40px !important;
  height: 10px !important;
}
.client-area-two .owl-dots .owl-dot:hover span {
  background-color: #fba311 !important;
  width: 40px !important;
  height: 10px !important;
}

.client-card {
  padding: 40px 30px;
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 30px;
}
.client-card .client-card-img {
  position: absolute;
  top: 40px;
  right: 30px;
  z-index: 1;
}
.client-card .client-card-img img {
  border-radius: 50%;
  border: 2px solid #fba311;
  width: 80%;
}
.client-card h3 {
  font-size: 20px;
  margin-bottom: 0;
}
.client-card span {
  font-size: 14px;
  margin-bottom: 15px;
}
.client-card ul {
  list-style: none;
  margin: 5px 0;
  padding: 0;
}
.client-card ul li {
  display: inline-block;
  font-size: 15px;
  color: #fba311;
}
.client-card p {
  margin-bottom: 0;
}
.client-card .client-card-quote {
  font-size: 35px;
  color: #fba311;
  margin-bottom: 0;
  margin-top: -35px;
  float: right;
}

.client-shape .shape1 {
  position: absolute;
  bottom: 15%;
  left: 0%;
}
.client-shape .shape2 {
  position: absolute;
  top: 30%;
  right: 15%;
  z-index: 1;
}
.client-shape .shape3 {
  position: absolute;
  top: 25%;
  right: 2%;
}

/*=================================
Client Area End
====================================*/
/*=================================
Leader Area
====================================*/
.leader-area {
  background-image: url(assets/img/bg-img.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}
.leader-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.2;
}
.leader-area::after {
  content: "";
  position: absolute;
  top: 0;
  left: 25%;
  width: 75%;
  height: 100%;
}

.leader-content {
  position: relative;
  margin-bottom: 30px;
}
.leader-content .section-title h2 {
  color: #ffffff;
  max-width: 600px;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
}
.leader-content .section-title p {
  color: #d1ccd2;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}

/*=================================
Leader Area End
====================================*/
/*==================================
Faq Area
=================================*/
.faq-accordion {
  max-width: 470px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 30px;
}
.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-item {
  display: block;
  background-color: #ffffff;
  margin-bottom: 15px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion-title {
  padding: 20px 60px 17px 20px;
  color: #301d44;
  text-decoration: none;
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 600;
  font-family: "Playfair Display", serif;
}
.faq-accordion .accordion .accordion-title i {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 25px;
  transition: 0.5s;
  font-size: 24px;
  width: 40px;
  height: 40px;
  color: #fba311;
  border-radius: 50px;
  border: 1px solid #fba311;
  text-align: center;
  line-height: 40px;
  background-color: transparent;
}
.faq-accordion .accordion .accordion-title.active i {
  transform: rotate(180deg);
  top: 15px;
}
.faq-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  margin-top: -5px;
  padding-bottom: 10px;
  padding-right: 30px;
  padding-left: 30px;
}
.faq-accordion .accordion .accordion-content p {
  line-height: 1.8;
}
.faq-accordion .accordion .accordion-content.show {
  display: block;
}

/*==================================
Faq Area End
=================================*/
/*=================================
Contact Area
====================================*/
.contact-form {
  padding: 45px;
  max-width: 800px;
  position: relative;
  z-index: 1;
}
.contact-form .section-title {
  margin-bottom: 30px;
}
.contact-form .section-title p {
  max-width: 415px;
  margin: 0 auto;
}
.contact-form .form-group {
  margin-bottom: 25px;
  position: relative;
}
.contact-form .form-group .form-control {
  height: 50px;
  color: #948b9f;
  border: 1px solid #ebebeb;
  background-color: #f8f9fb;
  font-size: 14px;
  padding: 10px 20px;
  width: 100%;
}
.contact-form .form-group .form-control:focus {
  outline: none;
  border-color: #fba311;
  box-shadow: none;
}
.contact-form .form-group textarea.form-control {
  height: auto;
}
.contact-form .with-errors {
  float: left;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0;
  color: #f00;
  font-weight: 400;
  display: block;
}
.contact-form .text-danger {
  font-size: 18px;
  margin-top: 15px;
}
.contact-form .default-btn {
  border: 0;
  outline: none;
  padding: 14px 60px;
}

.contact-form .form-group .form-control::-webkit-input-placeholder {
  color: #948b9f;
}

.contact-form .form-group .form-control:-ms-input-placeholder {
  color: #948b9f;
}

.contact-form .form-group .form-control::-ms-input-placeholder {
  color: #948b9f;
}

.contact-form .form-group .form-control::placeholder {
  color: #948b9f;
}

.contact-area-bg {
  background-color: #ffffff;
  z-index: 1;
  position: relative;
  margin-top: -200px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.contact-area-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-image: url(assets/img/contact/contact-img.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.contact-img {
  position: absolute;
  top: 0;
  right: 0;
}
.contact-img img {
  border-radius: 0 10px 10px 0;
}

.contact-card {
  margin-bottom: 30px;
  position: relative;
}
.contact-card i {
  width: 65px;
  height: 65px;
  line-height: 65px;
  background-color: #fff4e5;
  color: #fba311;
  display: inline-block;
  text-align: center;
  font-size: 26px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px;
}
.contact-card .content {
  padding-left: 85px;
}
.contact-card .content h3 {
  font-size: 20px;
  margin-bottom: 5px;
}
.contact-card .content p {
  margin-bottom: 0;
  color: #301d44;
}
.contact-card .content p a {
  color: #301d44;
}
.contact-card .content p a:hover {
  color: #fba311;
}
.contact-card .content span {
  margin-bottom: 0;
  color: #301d44;
}
.contact-card .content span a {
  color: #301d44;
}
.contact-card .content span a:hover {
  color: #fba311;
}

.apply-form .contact-form {
  padding: 0;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

/*=================================
Contact Area End
====================================*/
/*=================================
User All Form
====================================*/
.user-all-form {
  margin-bottom: 30px;
}
.user-all-form .contact-form {
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.user-all-form .contact-form .agree-label label {
  font-weight: 500;
  color: #301d44;
  margin-left: 10px;
}
.user-all-form .contact-form .forget {
  margin-bottom: 15px;
  float: right;
  color: #301d44;
  font-weight: 500;
}
.user-all-form .contact-form .forget:hover {
  color: #fba311;
}
.user-all-form .contact-form .account-desc {
  margin-top: 15px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0;
}
.user-all-form .contact-form .account-desc a {
  color: #301d44;
}
.user-all-form .contact-form .account-desc a:hover {
  color: #fba311;
}

/*=================================
User All Form End
====================================*/
/*=================================
404 Error Area
===================================*/
.error-area {
  border-top: 1px solid #eeeeee;
  padding-bottom: 100px;
  padding-top: 50px;
}
.error-area .error-content {
  text-align: center;
  position: relative;
}
.error-area .error-content img {
  max-width: 100%;
}
.error-area .error-content h3 {
  margin-bottom: 20px;
  position: relative;
  color: #fba311;
  font-size: 35px;
}
.error-area .error-content p {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
  color: #301d44;
}

/*=================================
404 Error Area End
===================================*/
/*==================================
Coming Soon Area
====================================*/
.coming-soon-area {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background-image: url(assets/img/inner-banner/inner-banner-6.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.coming-soon-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  background-color: #301d44;
  opacity: 0.7;
}
.coming-soon-area .coming-soon-content {
  text-align: center;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  padding: 50px 30px;
  border: 5px solid #aec5df;
}
.coming-soon-area .coming-soon-content h1 {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 60px;
  font-family: "Playfair Display", serif;
}
.coming-soon-area .coming-soon-content p {
  font-size: 16px;
  max-width: 600px;
  margin-top: 15px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
}
.coming-soon-area .coming-soon-content #timer {
  margin-top: 20px;
}
.coming-soon-area .coming-soon-content #timer div {
  display: inline-block;
  color: #ffffff;
  position: relative;
  margin-left: 35px;
  margin-right: 35px;
  font-size: 45px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
}
.coming-soon-area .coming-soon-content #timer div span {
  display: block;
  text-transform: capitalize;
  margin-top: -15px;
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
}
.coming-soon-area .coming-soon-content #timer div:last-child {
  margin-right: 0;
}
.coming-soon-area .coming-soon-content #timer div:last-child::before {
  display: none;
}
.coming-soon-area .coming-soon-content #timer div:first-child {
  margin-left: 0;
}
.coming-soon-area .coming-soon-content #timer div::before {
  content: "";
  position: absolute;
  right: -50px;
  top: -10px;
  font-size: 70px;
  color: #ffffff;
}
.coming-soon-area .coming-soon-content .newsletter-form {
  position: relative;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.coming-soon-area .coming-soon-content .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  height: 60px;
  border: none;
  background-color: #ffffff;
  padding-left: 15px;
  color: #ffffff;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 0;
  border: 1px solid #cccccc;
  color: #5d5d5d;
}
.coming-soon-area .coming-soon-content .newsletter-form .input-newsletter:focus {
  border-color: #fba311;
}
.coming-soon-area .coming-soon-content .newsletter-form .default-btn {
  border: 0;
  outline: 0;
  border-radius: 0 !important;
}
.coming-soon-area .coming-soon-content .newsletter-form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 60px;
  padding: 0 30px;
  text-transform: uppercase;
  outline: 0;
  color: #ffffff;
  transition: 0.5s;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}
.coming-soon-area .coming-soon-content .newsletter-form button::after {
  border-radius: 0;
}
.coming-soon-area .coming-soon-content .newsletter-form button::before {
  border-radius: 0;
}
.coming-soon-area .coming-soon-content .newsletter-form button:hover {
  color: #ffffff;
  background-color: #190f3c;
}
.coming-soon-area .coming-soon-content ul {
  list-style: none;
  margin-top: 30px;
  padding: 0;
}
.coming-soon-area .coming-soon-content ul li {
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 50px;
  font-size: 18px;
  background-color: #fba311;
  color: #ffffff;
  border-radius: 50px;
  margin-right: 10px;
}
.coming-soon-area .coming-soon-content ul li a {
  color: #ffffff;
}
.coming-soon-area .coming-soon-content ul li:hover {
  background-color: #5a3ac7;
}
.coming-soon-area #validator-newsletter {
  text-align: left;
  color: #dc3545 !important;
}

/*==================================
Coming Soon Area End
====================================*/
.single-content {
  margin-bottom: 30px;
}
.single-content h3 {
  font-size: 26px;
  color: #301d44;
  margin-bottom: 10px;
  font-family: "Playfair Display", serif;
}
.single-content p {
  margin-bottom: 0;
}

/*=================================
Map Area
====================================*/
.map-area iframe {
  display: block;
  width: 100%;
  height: 600px;
  border: none;
}

/*=================================
Map Area End
====================================*/
/*==============================
Pagination Area
=================================*/
.pagination-area {
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
}
.pagination-area .page-numbers {
  padding: 5px 17px;
  color: #301d44;
  text-align: center;
  display: inline-block;
  position: relative;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 18px;
  border-radius: 5px;
}
.pagination-area .page-numbers:hover {
  color: #fba311;
}
.pagination-area .page-numbers i {
  position: relative;
  font-size: 25px;
  top: 5px;
}
.pagination-area .page-numbers.current {
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

/*==============================
Pagination Area End
=================================*/
/*=================================
Footer Area
====================================*/
.footer-area {
  background-color: #f5f5f5;
}

.footer-widget {
  margin-bottom: 30px;
}
.footer-widget .footer-logo {
  margin-bottom: 30px;
}
.footer-widget h3 {
  margin-top: 30px;
  font-size: 24px;
  color: #301d44;
  margin-bottom: 30px;
}
.footer-widget p {
  margin-bottom: 20px;
}
.footer-widget .social-link {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer-widget .social-link li {
  display: inline-block;
  margin-right: 5px;
}
.footer-widget .social-link li a {
  border-radius: 3px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  width: 35px;
  height: 35px;
  line-height: 37px;
  text-align: center;
  color: #301d44;
  background-color: #ffffff;
  transition: 0.7s;
}
.footer-widget .social-link li a:hover {
  background-color: #fba311;
  color: #ffffff;
  border-radius: 50px;
}
.footer-widget .footer-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer-widget .footer-list li {
  display: block;
  color: #301d44;
  margin-bottom: 5px;
}
.footer-widget .footer-list li a {
  color: #301d44;
}
.footer-widget .footer-list li a:hover {
  color: #fba311;
  letter-spacing: 0.15px;
}
.footer-widget .footer-list-two {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer-widget .footer-list-two li {
  display: block;
  color: #301d44;
  margin-bottom: 7px;
  position: relative;
  padding-left: 30px;
}
.footer-widget .footer-list-two li i {
  color: #fba311;
  margin-right: 5px;
  position: absolute;
  left: 0;
  top: 3px;
  font-size: 20px;
}
.footer-widget .footer-list-two li a {
  color: #301d44;
}
.footer-widget .footer-list-two li:hover {
  color: #fba311;
}
.footer-widget .footer-list-two li:hover i {
  color: #301d44;
}
.footer-widget .footer-list-two li:hover a {
  color: #fba311;
}
.footer-widget .newsletter-form {
  position: relative;
  border-radius: 50px;
}
.footer-widget .newsletter-form .form-control {
  background: #301d44;
  color: #ffffff;
  height: 50px;
  line-height: 50px;
  margin: 0;
  border-radius: 5px;
  border: none;
  padding: 0 25px;
}
.footer-widget .newsletter-form .form-control:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.footer-widget .newsletter-form .default-btn {
  margin-top: 15px;
  outline: none;
  border: 0;
  padding: 12px 50px;
}
.footer-widget .newsletter-form .validation-danger {
  font-size: 16px;
  margin-top: 15px;
  color: red;
}

.footer-widget .newsletter-form .form-control::-webkit-input-placeholder {
  color: #948b9f;
}

.footer-widget .newsletter-form .form-control:-ms-input-placeholder {
  color: #948b9f;
}

.footer-widget .newsletter-form .form-control::-ms-input-placeholder {
  color: #948b9f;
}

.footer-widget .newsletter-form .form-control::placeholder {
  color: #948b9f;
}

.copy-right-area {
  padding: 15px;
  background-color: #301d44;
}
.copy-right-area .copy-right-text p {
  color: #ffffff;
  margin-bottom: 0;
}
.copy-right-area .copy-right-text p a {
  color: #fba311;
  border-bottom: 1px solid #fba311;
}
.copy-right-area .copy-right-text p a:hover {
  color: #5a3ac7;
  border-color: #ffffff;
}

/*=================================
Footer Area End
====================================*/
/*==================================
Back To Top Button
=====================================*/
#toTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  display: none;
  z-index: 99;
}

.top-btn {
  background-color: #fba311;
  color: #ffffff;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  box-shadow: 0 0 15px #fba311;
  font-size: 20px;
  display: inline-block;
  text-align: center;
  line-height: 45px;
  transition: 0.9s;
}
.top-btn:hover {
  background-color: #301d44;
  box-shadow: 0 0 15px #08104d;
  color: #fff;
}

/*==============================
Back To Top Button
=================================*/
/*==================================
Preloader CSS
=====================================*/
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #301d44;
}

.spinner {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 100px auto;
  animation: rotate-in 1.2s infinite ease-in-out;
}

/*==================================
Preloader CSS End
=====================================*/
/*==================================
Animation CSS
====================================*/
@keyframes scale {
  0%, 35% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
@-webkit-keyframes scale {
  0%, 35% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
@-webkit-keyframes rotate-in {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
@keyframes rotate-in {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
@-webkit-keyframes running-in {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(-10px, 150px);
    transform: translate(-10px, 150px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes running-in {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(-10px, 150px);
    transform: translate(-10px, 150px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@-webkit-keyframes rotated360 {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
}
@keyframes rotated360 {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
}
/*==================================
Animation CSS End
====================================*/